body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.simple-form-iterator-line > p {
  -webkit-flex: 1 0 3em;
          flex: 1 0 3em;
}

/* HACK: ra-customizable-datagridのタイトルが外から変えられないので無理やり変えた */
#ra-columns-dialog-title h2:lang(ja) {
  font-size: 0;
}

#ra-columns-dialog-title h2:lang(ja):before {
  content: '\8868\793A\8A2D\5B9A';
  font-size: 1.3125rem;
}

